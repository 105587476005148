<template>
  <div class="about">
    <div class="container">
      <div class="content-post mb-60">
        <br />
        <div class="page-title mb-60">
          <h2 class="text-center text-uppercase mb-0">Contact US</h2>
        </div>
        <div class="content-contact">
          <form class="contact-form" @submit="onSubmitupdate()">
            <div class="form-group">
              <input
                type="text"
                v-model="form.name"
                class="form-control"
                placeholder="Name"
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                v-model="form.subject"
                class="form-control"
                placeholder="Subject"
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                v-model="form.email"
                class="form-control"
                placeholder="Email Address"
              />
            </div>
            <div class="form-group">
              <textarea
                v-model="form.text"
                class="form-control"
                rows="5"
                placeholder="Message"
              ></textarea>
            </div>
            <button type="submit" class="btn-style1 btn-color1 btn btn-primary">
              send message
            </button>
          </form>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "about",
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: "utf-8" },
        { name: "description", content: this.description },
        { name: "title", content: this.meta },
      ],
      link: [{ rel: "canonical", href: this.current_url }],
    };
  },
  data(baseImageURL = "") {
    return {
      description: "defualt description",
      meta: "defualt meta",
      current_url: "/",
      title: "Contact Us",
      text: "",
      image: "",
      baseImageURL: baseImageURL,
      form: {
        email: "",
        name: "",
        subject: "",
        text: "",
      },
    };
  },
  components: {},
  methods: {
    onSubmitupdate() {
      console.log(this.form);
      // const vm = this;
      event.preventDefault();
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

      axios
        .post(this.$store.state.baseURL + "submit/contact", {
          data: this.form,
          headers: {
            // remove headers
          },
        })
        .then(function (response) {
          console.log(response);
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    },
  },
  mounted() {
    this.current_url = this.$store.state.siteURL + this.$route.path;
    // this.baseImageURL = this.$store.state.baseImageURL;
    // const vm = this;
    // axios.post(vm.$store.state.baseURL + 'about', {
    //     headers: {}
    // }).then(function (response,) {
    //     console.log();
    //     vm.description = response.data.response.meta_description;
    //     vm.meta = response.data.response.meta_title;
    //     vm.text = response.data.response.text;
    //     vm.image = response.data.response.image;
    // }).catch(error => {
    //     if (error) {
    //         console.log(error);
    //     }
    // });
  },
};
</script>
