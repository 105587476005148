<template>
    <div>


        <div class="row">
            <div class="col-12">
                <div class="card mb-4">
                    <div class="card-header pb-0">
                        <h6>Authors table</h6>
                    </div>
                    <div class="card-body px-0 pt-0 pb-2">
                        <div class="table-responsive p-0">
                            <table class="table align-items-center mb-0">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Blog
                                        </th>
                                        <th
                                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                            Category</th>
                                        <th
                                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Status</th>
                                        <th
                                            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                            Created</th>
                                        <th class="text-secondary opacity-7"></th>
                                        <th class="text-secondary opacity-7"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in blog_list" :key="item.id">
                                        <td>
                                            <div class="d-flex px-2 py-1">
                                                <div>
                                                    <img :src="baseImageURL + item.feature_image"
                                                        class="avatar avatar-sm me-3" alt="user1">
                                                </div>
                                                <div class="d-flex flex-column justify-content-center">
                                                    <h6 class="mb-0 text-sm">{{ item.title.slice(0, 20) }}<span
                                                            v-if="item.title.length >= 20">...</span></h6>

                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <p class="text-xs font-weight-bold mb-0">{{ item.category.name }}</p>
                                            <p class="text-xs text-secondary mb-0">{{ item.subcategory.name }}</p>
                                        </td>
                                        <td class="align-middle text-center text-sm">
                                            <span class="badge badge-sm bg-gradient-success" v-if="item.publish === 1">
                                                Online </span>
                                            <span class="badge badge-sm bg-gradient-danger" v-else>Offline</span>
                                        </td>
                                        <td class="align-middle text-center">
                                            <span class="text-secondary text-xs font-weight-bold">{{ item.created_at}}</span>
                                        </td>
                                        <td class="align-middle">
                                            <a href="javascript:;" class="text-secondary font-weight-bold text-xs"
                                                data-toggle="tooltip" data-original-title="Edit user"
                                                v-on:click="toggleModal(true, item.id)">
                                                <span class="material-symbols-outlined"
                                                    style="background-color:rgb(3, 175, 3) !important;padding: 7px; color: white;border-radius: 50%;">
                                                    edit
                                                </span>
                                            </a>
                                        </td>
                                        <td class="align-middle">
                                            <a href="javascript:;" class="text-secondary font-weight-bold text-xs"
                                                data-toggle="tooltip" data-original-title="Delete user"
                                                v-on:click="deleteData(item.id)">
                                                <span class="material-symbols-outlined"
                                                    style="background-color:red !important;padding: 7px; color: white;border-radius: 50%;">
                                                    delete
                                                </span>
                                            </a>
                                        </td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>





        <!-- Modal -->
        <div :style="style" class="modal fade ghulam-ali-modal " id="exampleModal" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class=" container" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Update Blog</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            v-on:click="toggleModal(false, 'close')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="row">


                        <form action="" style="padding: 20px 30px">
                            <div class="row pb-4">
                                <div class="col-lg-3 col-md-6 col-sm-12">
                                    <label for="category">Category*</label>
                                    <select name="category" id="category" class="form-select"
                                        aria-label="Default select example" v-model="categorey"
                                        @change="subCategoryFetch($event)">
                                        <option selected>Open this select menu</option>

                                        <option v-for="item in categorey_list" :value="item.id" :key="item.id">{{ item.name
                                        }}</option>

                                    </select>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-12">
                                    <label for="subcategory">Sub Category*</label>
                                    <select name="subcategory" id="subcategory" class="form-select"
                                        aria-label="Default select example" v-model="sucategorey">
                                        <option selected>Open this select menu</option>
                                        <option v-for="item in sub_categorey_list" :value="item.id" :key="item.id">{{
                                            item.name }}</option>
                                    </select>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-12">
                                    <label for="blogimage" class="form-label">Blog Image*</label>
                                    <input class="form-control" type="file" name="blogimage" id="blogimage"
                                        @change="onFileSelected($event)">
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-12">
                                    <label for="blogimagealt" class="form-label">Blog Image Alt*</label>
                                    <input class="form-control" type="text" name="blogimagealt" id="blogimagealt"
                                        v-model="imagealt">
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <label for="blogtitle">Blog Title*</label>
                                    <input type="text" class="form-control" id="blogtitle" name="blogtitle" v-model="title">
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <label for="editor">Blog Content*</label>
                                    <div class="form-group ghulam-ali-editor">
                                        <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4 col-sm-12">
                                    <label for="blogmetatitle">Blog Title*</label>
                                    <input type="text" class="form-control" id="blogmetatitle" name="blogmetatitle"
                                        v-model="meta_title">
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-12">
                                    <label for="blogmetadescription">Blog Meta Description*</label>
                                    <input type="text" class="form-control" id="blogmetadescription"
                                        name="blogmetadescription" v-model="meta_description">
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-12">
                                    <label for="publish">Publish*</label>
                                    <select name="publish" id="publish" class="form-select"
                                        aria-label="Default select example" v-model="publish">
                                        <option value="1">Publish</option>

                                        <option value="0">No</option>

                                    </select>
                                </div>


                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <label for="tags">Tags</label>
                                    <vue-tags-input value="" id="tags" class="ghulam-ali-tags" @tags-changed="updateTags" />

                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12">

                                    <button type="button" class="btn"
                                        style="float:right;background-color:#cb0c9f ;color: white;"
                                        v-on:click="updateData()">Update Blog</button>

                                </div>
                            </div>

                        </form>



                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
<style>
.material-symbols-outlined {
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48
}

.ghulam-ali-modal {

    background: #000000b8;
    padding-top: 5rem;
}

.ghulam-ali-tags {
    width: 100%;
    max-width: 100% !important;
}

.ghulam-ali-editor .ck-content {
    height: 150px;
}
</style>
<script>
// import $ from 'jquery';
import axios from 'axios';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import VueTagsInput from '@johmun/vue-tags-input';
// import moment from 'moment';

export default {
    name: "ManageBlogs",
    metaInfo: {
        base: { href: "/manage-blogs" },
        script: [



        ],
        link: [

            { rel: "stylesheet", href: "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" },

        ],

        meta: [
            { charset: "utf-8" },
            { name: "viewport", content: "width=device-width, initial-scale=1" },
            {
                property: "og:title",
                content: "Test title",
            },
            {
                vmid: "image",
                name: "image",
                content: "image image",
            },
            {
                vmid: "twitter:card",
                name: "twitter:card",
                content: "twitter:card   <sanitized>",
            },
        ],
    },

    components: {
        VueTagsInput,
    },
    computed: {
        tagsInputModel: {
            get() {
                return this.tags;
            },
            set(value) {
                this.tags = value;
            },
        },
    },
    data(baseImageURL = "") {
        return {


            blog_list: [],
            baseImageURL: baseImageURL,

            style: { display: "none", opacity: 0, },

            editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {
                // The configuration of the editor.
            },
            tags: [],

            title: "",
            categorey: "",
            sucategorey: "",
            image: "",
            content: "",

            meta_title: "",
            meta_description: "",
            imagealt: "",
            publish: 1,
            categorey_list: "",
            sub_categorey_list: [],
            update_id: "",
            date:"",
            time:"",
            // temp:"",

        };
    },



    mounted() {


        axios.post(this.$store.state.baseURL + 'all_category/')
            .then(response => {
                // Handle the response from the API
                console.log(response.data);

                this.categorey_list = response.data.data;


            })
            .catch(error => {
                // Handle any errors that occurred during the request
                console.error(error);
            });



        this.baseImageURL = this.$store.state.baseImageURL;


        axios.post(this.$store.state.baseURL + 'user/post/')
            .then(response => {
                // Handle the response from the API
                console.log(response.data.data);

                this.blog_list = response.data.data;


            })
            .catch(error => {
                // Handle any errors that occurred during the request
                console.error(error);
            });


    },
    methods: {
        subCategoryFetch(event) {


            console.log("id = " + event.target.value);
            axios.post(this.$store.state.baseURL + 'sub_category/' + event.target.value)
                .then(response => {
                    // Handle the response from the API
                    console.log(response.data.sub_category);

                    this.sub_categorey_list = response.data.sub_category;


                })
                .catch(error => {
                    // Handle any errors that occurred during the request
                    console.error(error);
                });


        },
        toggleModal(attribute, id) {



            if (id !== "close") {
                axios.post(this.$store.state.baseURL + 'user/post/edit/' + id)
                    .then(response => {
                        // Handle the response from the API
                        console.log(response.data);
                        let edit_data = response.data;
                        this.update_id = edit_data.data.id;
                        this.title = edit_data.data.title;
                        this.meta_description = edit_data.data.meta_description;
                        this.meta_title = edit_data.data.meta_title;
                        this.publish = edit_data.data.publish;
                        this.categorey = edit_data.data.category_id;

                        // this.temp = this.sucategorey;
                        this.imagealt = edit_data.data.image_alt;
                        this.tags = edit_data.data.tags;
// console.log(edit_data.data.tags);
                        axios.post(this.$store.state.baseURL + 'sub_category/' + this.categorey)
                            .then(response => {
                                // Handle the response from the API

                                console.log("Sub Categories : ");
                                console.log(response.data.sub_category);

                                this.sub_categorey_list = response.data.sub_category;
                                this.sucategorey = edit_data.data.subcategory_id;

                            })
                            .catch(error => {
                                // Handle any errors that occurred during the request
                                console.error(error);
                            });




                    })
                    .catch(error => {
                        // Handle any errors that occurred during the request
                        console.error(error);
                    });








            }

            attribute ? this.style = { display: "initial", opacity: 1, } : this.style = { display: "none", opacity: 0, }




        },
        updateData() {

            this.style = { display: "none", opacity: 0, }


            const formData = new FormData();

            // console.log(formData);

            let temptags = [];



            if (this.tags) {
                this.tags.forEach((item) => {
                    temptags.push(item['text']);
                });
            }
            else{
                this.tags = ["blogverse"];
            }
            console.log("tags=" +temptags);
            // console.log(formData);
            // Append your form data to the FormData object
            formData.append('title', this.title);
            formData.append('content', this.editorData);
            formData.append('feature_image', this.image);
            formData.append('image_alt', this.imagealt);
            formData.append('meta_title', this.meta_title);
            formData.append('meta_description', this.meta_description);
            formData.append('tags', temptags);
            formData.append('category_id', this.categorey);
            formData.append('subcategory_id', this.sucategorey);
            formData.append('publish', this.publish);

           

           
           
           
           
    //         axios.put(this.$store.state.baseURL + 'user/post/update/' + this.update_id, formData,
                
    //   )
    
    axios.post(
    this.$store.state.baseURL + 'user/post/update1/data/' + this.update_id,
    formData,
    {
      headers: {
        'Content-Type': 'application/json' // Set the correct content type for form data
      }
    }
  )
    .then(response => {
      // Handle the response from the API
      console.log(response.data);
    })
    .catch(error => {
      // Handle any errors that occurred during the request
      console.error(error);
    });





            axios.post(this.$store.state.baseURL + 'user/post/')
                .then(response => {
                    // Handle the response from the API
                    console.log(response.data.data);

                    this.blog_list = response.data.data;


                })
                .catch(error => {
                    // Handle any errors that occurred during the request
                    console.error(error);
                });



        },

        deleteData(id) {

            // alert("Deleting....");

            axios.post(this.$store.state.baseURL + 'user/post/destory/' + id)
                .then(response => {
                    // Handle the response from the API
                    console.log(response.data);




                })
                .catch(error => {
                    // Handle any errors that occurred during the request
                    console.error(error);
                });



            axios.post(this.$store.state.baseURL + 'user/post/')
                .then(response => {
                    // Handle the response from the API
                    console.log(response.data.data);

                    this.blog_list = response.data.data;


                })
                .catch(error => {
                    // Handle any errors that occurred during the request
                    console.error(error);
                });


        },

        updateTags(tags) {
            this.tags = tags;
        },
        onFileSelected(event) {
            this.image = event.target.files[0];

        },

    },
};

</script>