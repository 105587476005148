<template>
  <div>
    <div class="row">
      <div class="card">
        <h2>Create Your Blog</h2>
        <form action="">
          <div class="row pb-4">
            <div class="col-lg-3 col-md-6 col-sm-12">
              <label for="category">Category*</label>
              <select name="category" id="category" class="form-select" aria-label="Default select example"  v-model="categorey"  @change="subCategoryFetch($event)">
                <option selected>Open this select menu</option>
                
                <option v-for="item in categorey_list" :value="item.id" :key="item.id" >{{ item.name }}</option>
                
              </select>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <label for="subcategory">Sub Category*</label>
              <select name="subcategory" id="subcategory" class="form-select" aria-label="Default select example" v-model="sucategorey">
                <option selected>Open this select menu</option>
                <option v-for="item in sub_categorey_list" :value="item.id" :key="item.id" >{{ item.name }}</option>
              </select>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <label for="blogimage" class="form-label">Blog Image*</label>
              <input class="form-control" type="file" name="blogimage" id="blogimage" @change="onFileSelected($event)">
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
              <label for="blogimagealt" class="form-label">Blog Image Alt*</label>
              <input class="form-control" type="text" name="blogimagealt" id="blogimagealt" v-model="imagealt">
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <label for="blogtitle">Blog Title*</label>
              <input type="text" class="form-control" id="blogtitle" name="blogtitle" v-model="title">
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <label for="editor">Blog Content*</label>
              <div class="form-group ghulam-ali-editor">
                <ckeditor :editor="editor" v-model="editorData" :config="editorConfig" ></ckeditor>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-12">
              <label for="blogmetatitle">Blog Title*</label>
              <input type="text" class="form-control" id="blogmetatitle" name="blogmetatitle" v-model="meta_title">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <label for="blogmetadescription">Blog Meta Description*</label>
              <input type="text" class="form-control" id="blogmetadescription" name="blogmetadescription" v-model="meta_description">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <label for="publish">Publish*</label>
              <select name="publish" id="publish" class="form-select" aria-label="Default select example"  v-model="publish" >
                <option value="1">Publish</option>
                
                <option value="0">No</option>
                
              </select>
            </div>


            <div class="col-lg-12 col-md-12 col-sm-12">
              <label for="tags">Tags</label>
              <vue-tags-input  id="tags" class="ghulam-ali-tags" @tags-changed="updateTags" />

            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">

              <button type="button" class="btn" style="float:right;background-color:#cb0c9f ;color: white;" v-on:click="onCreateBlog()">Create Blog</button>

            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</template>
<style>
.ghulam-ali-editor .ck-content {
  height: 200px;
}

.ghulam-ali-tags {
  width: 100%;
  max-width: 100% !important;
}

.ghulam-ali-tags .ti-input {
  border-radius: 7px !important;
}

ti-tag[data-v-61d92e31] {
  background-color: #cb0c9f !important;
}
</style>
<script>
// import tinymce from 'tinymce/tinymce';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import $ from 'jquery';
// import VueTagInput from 'vue-tag-input';
import VueTagsInput from '@johmun/vue-tags-input';
import axios from 'axios';
import router from '@/router';

export default {
  name: "CreateBlogsView",
  metaInfo: {
    base: { href: "/create-blog" },
    script: [

      { src: "https://cdn.tiny.cloud/1/no-api-key/tinymce/6/tinymce.min.js", async: true, defer: true },

    ],
    link: [

      // { rel: "stylesheet", href: "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" },

    ],

    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        property: "og:title",
        content: "Test title",
      },
      {
        vmid: "image",
        name: "image",
        content: "image image",
      },
      {
        vmid: "twitter:card",
        name: "twitter:card",
        content: "twitter:card   <sanitized>",
      },
    ],
  },

  components: {
    VueTagsInput,
  },
  computed:{
    tagsInputModel: {
    get() {
      return this.tags;
    },
    set(value) {
      this.tags = value;
    },
  },
  },
  data(baseImageURL = "") {
    return {
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        // The configuration of the editor.
      },
      tags: [],
      baseImageURL: baseImageURL,
      title:"",
      categorey:"",
      sucategorey:"",
      image:"",
      content:"",
  
      meta_title:"",
      meta_description:"",
      imagealt:"",
      publish:1,
      categorey_list : "",
      sub_categorey_list : [],


    };
  },



  mounted() {
  
    // this.baseImageURL = this.$store.state.baseImageURL;



    axios.post(this.$store.state.baseURL +'all_category/')
      .then(response => {
        // Handle the response from the API
        console.log(response.data);

        this.categorey_list = response.data.data; 


      })
      .catch(error => {
        // Handle any errors that occurred during the request
        console.error(error);
      });


  },
  methods: {
    subCategoryFetch(event){


 console.log("id = "+event.target.value);
      axios.post(this.$store.state.baseURL +'sub_category/'+ event.target.value)
      .then(response => {
        // Handle the response from the API
        console.log( response.data.sub_category);
       
        this.sub_categorey_list = response.data.sub_category; 


      })
      .catch(error => {
        // Handle any errors that occurred during the request
        console.error(error);
      });


  },

  onCreateBlog() {
  const formData = new FormData();
  let temptags = [];

  this.tags.forEach((item) => {
    temptags.push(item['text']);
  });
  console.log(temptags);

  // Append your form data to the FormData object
  formData.append('title', this.title);
  formData.append('content', this.editorData);
  formData.append('feature_image', this.image);
  formData.append('image_alt', this.imagealt);
  formData.append('meta_title', this.meta_title);
  formData.append('meta_description', this.meta_description);
  formData.append('tags', temptags);
  formData.append('category_id', this.categorey);
  formData.append('subcategory_id', this.sucategorey);
  formData.append('publish', this.publish);

  axios
    .post(this.$store.state.baseURL + 'post1/store2', formData)
    .then((response) => {
      // Handle the response from the API
      console.log(response.data);
    })
    .catch((error) => {
      // Handle any errors that occurred during the request
      console.error(error);
    });

  this.title = "";
  this.editorData = "";
  this.image = "";
  this.imagealt = "";
  this.meta_title = "";
  this.meta_description = "";
 
 // Resetting the tags value to an empty array
 this.tags =[];
  this.categorey = "";
  this.sucategorey = "";
  this.publish = "";


  router.push('/admin/manage-blogs');
},
    updateTags(tags) {
    this.tags = tags;
  },
  onFileSelected(event) {
    this.image = event.target.files[0];
    
  },
 

  },
};

</script>