<template>
  <div class="about">
    <div class="container">
      <div class="content-post mb-60">
        <br />
        <div class="page-title mb-60">
          <h2 class="text-center text-uppercase mb-0">About</h2>
        </div>
        <div class="page-feature single-img">
          <!--                    <img class="img_fluid"-->
          <!--                         src="https://images.unsplash.com/photo-1517164850305-99a3e65bb47e?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjExNzczfQ&amp;s=bd8f92eb667099feaff7cab5995de691"-->
          <!--                         alt="About">-->
          <b-img
            class="img_fluid"
            thumbnail
            rounded
            :src="baseImageURL + image"
            fluid
            alt="Responsive image"
          ></b-img>
        </div>
        <br />
        <div class="kg-card-markdown" v-html="text"></div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "about",
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: "utf-8" },
        { name: "description", content: this.description },
        { name: "title", content: this.meta },
      ],
      link: [{ rel: "canonical", href: this.current_url }],
    };
  },
  data(baseImageURL = "") {
    return {
      description: "defualt description",
      meta: "defualt meta",
      current_url: "/",
      title: "About",
      text: "",
      image: "",

      baseImageURL: baseImageURL,
    };
  },
  components: {},
  mounted() {
    this.current_url = this.$store.state.baseURL + this.$route.path;
    this.baseImageURL = this.$store.state.baseImageURL;
    const vm = this;

    axios
      .post(vm.$store.state.baseURL + "about", {
        headers: {},
      })
      .then(function (response) {
        console.log();
        vm.description = response.data.response.meta_description;
        vm.meta = response.data.response.meta_title;
        vm.text = response.data.response.text;
        vm.image = response.data.response.image;
      })
      .catch((error) => {
        if (error) {
          console.log(error);
        }
      });
  },
};
</script>
