<template> 
   <div class="row">
        <div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-capitalize font-weight-bold">Total Blogs</p>
                    <h5 class="font-weight-bolder mb-0">
                      {{ blogs_count }}
                      
                    </h5>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                    <i class="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-capitalize font-weight-bold">Online Blogs</p>
                    <h5 class="font-weight-bolder mb-0">
                     {{ online_count }}
                    
                    </h5>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                    <i class="ni ni-world text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="col-xl-4 col-sm-6">
          <div class="card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-capitalize font-weight-bold">Offline Blogs</p>
                    <h5 class="font-weight-bolder mb-0">
                      {{offline_count}}
                      
                    </h5>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-primary shadow text-center border-radius-md">
                    <i class="ni ni-cart text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>


import axios from 'axios';

export default {
  name: "Dashboard",
  metaInfo: {
    base: { href: "/dashboardys" },
    script: [

     
    ],
    link: [

      // { rel: "stylesheet", href: "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" },

    ],

    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        property: "og:title",
        content: "Test title",
      },
      {
        vmid: "image",
        name: "image",
        content: "image image",
      },
      {
        vmid: "twitter:card",
        name: "twitter:card",
        content: "twitter:card   <sanitized>",
      },
    ],
  },

  components: {
    
  },
  computed:{
  
  },
  data() {
    return {
      
blogs_count:0,
online_count:0,
offline_count:0,

    };
  },



  mounted() {
  
    // this.baseImageURL = this.$store.state.baseImageURL;



    axios.post(this.$store.state.baseURL +'user/blog/counts/')
      .then(response => {
        // Handle the response from the API
        console.log(response.data);

        // this.categorey_list = response.data.data; 
        this.offline_count = response.data.blogsofflinecount;
        this.online_count = response.data.blogsonlinecount;
        this.blogs_count = response.data.blogscount;

      })
      .catch(error => {
        // Handle any errors that occurred during the request
        console.error(error);
      });


  },
  methods: {

 

  },
};

</script>